export default {
  gallery: {
    speed: 750,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },
    slidesPerView: 1,
    spaceBetween: 8,
  },
  galleryExperiences: {
    speed: 750,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
    slidesPerView: 1,
    spaceBetween: 8,
    breakpoints: {
      260: {
        slidesPerView: 1.1,
      },
      640: {
        slidesPerView: 1.25,
      },
      1024: {
        slidesPerView: 2.5,
      },
    },
  },
  'gallery-thumbs': {
    speed: 750,
    init: true,
    spaceBetween: 8,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 8,
      loadOnTransitionStart: true,
    },
    slidesPerView: 'auto',
    breakpoints: {
      260: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 'auto',
      },
    },
  },
  news: {
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 80,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      480: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 8,
      },
    },
    slidesPerView: 1.1,
    lazy: {
      loadOnTransitionStart: true,
      loadPrevNext: true,
      loadPrevNextAmount: 5,
    },
    speed: 750,
  },
  hotels: {
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 32,
      },
      480: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 8,
      },
    },
    slidesPerView: 1,
    lazy: {
      loadOnTransitionStart: true,
      loadPrevNext: true,
      loadPrevNextAmount: 5,
    },
    speed: 750,
    watchSlidesVisibility: true,
  },
  team: {
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      480: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 8,
      },
    },
    slidesPerView: 1.2,
    lazy: {
      loadOnTransitionStart: true,
      loadPrevNext: true,
      loadPrevNextAmount: 1,
    },
    speed: 750,
    watchSlidesVisibility: true,
  },
  crossfade: {
    speed: 750,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1,
      loadOnTransitionStart: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    navigation: {
      nextEl: '[data-swiper-inner-next]',
      prevEl: '[data-swiper-inner-prev]',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    init: true,
    nested: true,
    observer: true,
    observeParents: true,
    noSwipingClass: 'swiper-no-swiping-inner',
  },
}
