import Rellax from 'rellax'
import barba from '@barba/core'
import { PARALLAX_CLASS } from './_constants'

export default function initParallax() {
  if (document.querySelector(`.${PARALLAX_CLASS}`)) {
    new Rellax(`.${PARALLAX_CLASS}`)
  }

  barba.hooks.after(({ next }) => {
    if (next.container.querySelector(`.${PARALLAX_CLASS}`)) {
      new Rellax(`.${PARALLAX_CLASS}`)
    }
  })
}
