import Muuri from 'muuri'
import anime from 'animejs'
import {
  ACTIVE_CLASS,
  FILTER_BUTTON_ATTR,
  FILTER_GRID_ATTR,
  FILTER_GROUP_ATTR,
  FILTER_GROUP_MULTIPLE_ATTR,
  FILTER_ITEM_ATTR,
} from './_constants'

export default class InitFilters {
  constructor(container, swiper = null) {
    this.container = container
    this.images = this.container.querySelectorAll('[data-lazy-image]')
    this.groups = this.container.querySelectorAll(`[${FILTER_GROUP_ATTR}]`)
    this.grid = swiper
      ? null
      : new Muuri(container.querySelector(`[${FILTER_GRID_ATTR}]`), {
          items: `[${FILTER_ITEM_ATTR}]`,
        })
    this.swiper = swiper
    this.buttons = this.container.querySelectorAll(`[${FILTER_BUTTON_ATTR}]`)

    this.init()
  }

  getAllGroupsItems() {
    return [...this.groups].map(group => [
      ...group.querySelectorAll(`[${FILTER_BUTTON_ATTR}]`),
    ])
  }

  getActiveGroups() {
    return this.getAllGroupsItems().map(group =>
      group
        .filter(btn => btn.classList.contains(ACTIVE_CLASS))
        .map(btn =>
          btn
            .getAttribute(FILTER_BUTTON_ATTR)
            .toLowerCase()
            .trim()
        )
    )
  }

  // eslint-disable-next-line class-methods-use-this
  toggleActive(button) {
    if (button.classList.contains(ACTIVE_CLASS)) {
      button.classList.remove(ACTIVE_CLASS)
    } else {
      button.classList.add(ACTIVE_CLASS)
    }
  }

  filterGrid(activeGroups) {
    if (activeGroups.reduce((a, c) => a + c.length, 0)) {
      this.grid.filter(item => {
        const itemGroups = item
          .getElement()
          .getAttribute(FILTER_ITEM_ATTR)
          .split(',')
          .map(el => el.toLowerCase().trim())

        return activeGroups.every(group =>
          group.length ? group.some(g => itemGroups.includes(g)) : true
        )
      })
    } else {
      this.grid.show([...this.grid.getItems()])
    }
  }

  filterSwiper(activeGroups) {
    const swiperSlides = this.swiper.slides

    anime({
      targets: swiperSlides,
      duration: 300,
      opacity: 0,
      scale: 0.5,
      easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      complete: () => {
        if (activeGroups.reduce((a, c) => a + c.length, 0)) {
          swiperSlides.forEach(slide => {
            const itemGroups = slide
              .getAttribute(FILTER_ITEM_ATTR)
              .split(',')
              .map(el => el.toLowerCase().trim())

            if (
              !activeGroups.every(group =>
                group.length ? group.some(g => itemGroups.includes(g)) : true
              )
            ) {
              slide.style.display = 'none'
            } else {
              slide.style.display = 'initial'
            }
          })
        } else {
          swiperSlides.forEach(slide => (slide.style.display = 'initial'))
        }

        this.swiper.update()
        this.swiper.slideTo(0)

        anime({
          targets: swiperSlides,
          duration: 300,
          opacity: 1,
          scale: 1,
          easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        })
      },
    })
  }

  init() {
    this.groups.forEach(group => {
      const isMultiple = group.hasAttribute(FILTER_GROUP_MULTIPLE_ATTR)
      const buttons = group.querySelectorAll(`[${FILTER_BUTTON_ATTR}]`)

      buttons.forEach(button => {
        button.addEventListener('click', () => {
          if (isMultiple) {
            this.toggleActive(button)
          } else {
            buttons.forEach(btn => btn.classList.remove(ACTIVE_CLASS))
            button.classList.add(ACTIVE_CLASS)
          }

          if (this.swiper) {
            this.filterSwiper(this.getActiveGroups())
          } else {
            this.filterGrid(this.getActiveGroups())
          }
        })
      })
    })

    this.images.forEach(img => {
      img.addEventListener('load', () => {
        if (this.swiper) {
          this.swiper.update()
        } else {
          this.grid.layout(true)
        }
      })
    })
  }
}
