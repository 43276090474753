import anime from 'animejs'
import scrollPos from './helpers/scroll-position'
import { scroll as scrollTo } from './scroll-to'
import debounce from './helpers/debounce'
import { header } from './_constants'

export default function sectionCounter(page) {
  const headerHeight = header.clientHeight
  const setTextForLabel = (current, total, label) => {
    label.innerText = `${current} / ${total}`
  }
  const getScrollTop = el => {
    return el.getBoundingClientRect().top + scrollPos() - headerHeight
  }
  const sections = [...page.querySelectorAll('[data-section]')]

  if (!sections.length) return

  const totalSections = sections.length
  const counter = page.querySelector('[data-section-counter]')
  const arrows = counter.querySelectorAll('[data-arrow]')
  const label = counter.querySelector('[data-label]')
  const lines = counter.querySelectorAll('[data-line]')
  const linesBg = counter.querySelectorAll('[data-line-bg]')
  const darkColor = counter.dataset.dark
  const lightColor = counter.dataset.light
  const duration = 300
  let currentNumber = null

  const sectionObserver = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          currentNumber = sections.indexOf(entry.target) + 1
          const currentProgress = currentNumber / totalSections

          if ('dark' in entry.target.dataset) {
            anime({
              targets: [...lines, ...linesBg],
              backgroundColor: darkColor,
            })
            anime({
              targets: [...arrows, label],
              color: darkColor,
              duration,
            })
          } else {
            anime({
              targets: [...lines, ...linesBg],
              backgroundColor: lightColor,
              duration,
            })
            anime({
              targets: [...arrows, label],
              color: lightColor,
              duration,
            })
          }

          if (currentProgress > 0.5) {
            anime
              .timeline({ duration })
              .add({
                targets: lines[0],
                scaleY: 1,
              })
              .add({
                targets: lines[1],
                scaleY: (currentProgress - 0.5) * 2,
              })

            if (currentNumber === totalSections) {
              anime({
                targets: arrows[1],
                opacity: 1,
                duration,
              })
            } else {
              anime({
                targets: arrows[1],
                opacity: 0.3,
                duration,
              })
            }
          } else {
            anime
              .timeline({ duration })
              .add({
                targets: lines[1],
                scaleY: 0,
              })
              .add({
                targets: lines[0],
                scaleY: currentProgress * 2,
              })
          }

          setTextForLabel(currentNumber, totalSections, label)
        }
      })
    },
    { rootMargin: `-35% 0px -35%` }
  )

  const debouncedTop = debounce(() => {
    const prevSectionIndex = sections.indexOf(sections[currentNumber - 2])

    if (prevSectionIndex !== -1) {
      scrollTo(getScrollTop(sections[prevSectionIndex]))
    }
  }, 300)
  const debouncedBottom = debounce(() => {
    const nextSectionIndex = sections.indexOf(sections[currentNumber])

    if (nextSectionIndex !== -1) {
      scrollTo(getScrollTop(sections[nextSectionIndex]))
    }
  }, 300)

  arrows[0].addEventListener('click', debouncedTop)
  arrows[1].addEventListener('click', debouncedBottom)

  if (totalSections > 0) {
    anime({
      targets: arrows[0],
      opacity: 1,
      duration: 100,
    })
    setTextForLabel(1, totalSections, label)
  }

  sections.forEach(section => sectionObserver.observe(section))
}
