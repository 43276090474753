import 'swiper/swiper-bundle.css'
import Swiper from 'swiper/bundle'
import deepmerge from 'deepmerge'
import initPhotoSwipe from './init-photo-swipe'
import swiperOverrides from './swiper-overrides'
import createEvent from './helpers/createEvent'
import InitFilters from './InitFilters'

const swiperObserver = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const el = entry.target
        const container =
          'swiperMultiple' in el.dataset
            ? el
            : el.querySelector(
                '.swiper-container:not(.swiper-container-thumbs)'
              ) || el
        let config = {
          keyboard: true,
          lazy: true,
          navigation: true,
          preloadImages: false,
          watchSlidesVisibility: true,
          init: false,
        }
        let thumbsConfig = {
          ...config,
        }

        if ('override' in container.dataset) {
          config = deepmerge(
            config,
            swiperOverrides[container.dataset.override]
          )
        }

        const prevEl = container.querySelector('[data-swiper-prev]')
        const nextEl = container.querySelector('[data-swiper-next]')
        if (nextEl && prevEl) {
          config.navigation = { nextEl, prevEl }
        }

        const thumbs = el.querySelector('[data-swiper-thumbs]')

        if (thumbs) {
          if ('override' in thumbs.dataset) {
            thumbsConfig = deepmerge(
              thumbsConfig,
              swiperOverrides[thumbs.dataset.override]
            )
          }

          config.thumbs = {
            swiper: new Swiper(thumbs, thumbsConfig),
          }
        }

        const swiper = new Swiper(container, config)

        const { swiperFilter } = el.dataset

        if (swiperFilter) {
          new InitFilters(
            document.querySelector(
              `[data-swiper-filter-container="${swiperFilter}"]`
            ),
            swiper
          )
        }

        swiper.on('init', () => {
          window.dispatchEvent(createEvent('swiperReady'))

          if (swiper.el.hasAttribute('data-swiper-fullscreen')) {
            const fsButton = swiper.el.querySelector(
              '[data-swiper-fullscreen-button]'
            )

            initPhotoSwipe(swiper)

            fsButton.addEventListener('click', () => {
              swiper.slides[swiper.activeIndex]
                .querySelector('.swiper-image')
                .click()
            })
          }
        })
        swiper.on('progress', () => {
          swiper.slidesGrid.forEach((slidePos, i) => {
            if (slidePos + swiper.translate < swiper.size + 200) {
              const slide = swiper.slides[i]
              if (!slide.querySelector('.swiper-lazy-loaded')) {
                swiper.lazy.loadInSlide(i)
              }
            }
          })
        })
        swiper.init()

        el.querySelectorAll('[data-swiper-inner]').forEach(innerSwiper => {
          let options = config

          if ('override' in innerSwiper.dataset) {
            options = deepmerge(
              options,
              swiperOverrides[innerSwiper.dataset.override]
            )
          }

          new Swiper(innerSwiper, options)
        })

        swiperObserver.unobserve(entry.target)
      }
    })
  },
  {
    rootMargin: '500px 0px',
  }
)

export default swiperObserver
