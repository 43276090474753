import anime from 'animejs'
import scrollPos from './helpers/scroll-position'
import { header } from './_constants'

const targets = [document.documentElement, document.body]

export function scroll(scrollTop) {
  anime.remove(targets)
  anime({
    targets,
    scrollTop,
    duration: Math.min(2000, Math.max(1000, scrollPos())),
    easing: 'cubicBezier(.5,0,0,1)',
  })
}

function initScrollTo(el) {
  const { scrollTo } = el.dataset

  el.addEventListener('click', e => {
    let scrollTop

    e.preventDefault()

    if (scrollTo === 'top') {
      scrollTop = 0
    } else {
      const target = document.querySelector(scrollTo)
      if (target) {
        const { top } = target.getBoundingClientRect()
        scrollTop = top + scrollPos()
      }
      if (header) {
        scrollTop -= header.clientHeight
        scrollTop = Math.max(scrollTop, 0)
      }
    }
    scroll(scrollTop)
  })
}

export default initScrollTo
