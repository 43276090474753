import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import onEscape from './helpers/escape-key'
import { ACTIVE_CLASS } from './_constants'

document.addEventListener('DOMContentLoaded', () => {
  const videoButton = document.querySelector('[data-video-button]')
  const videoClose = document.querySelector('[data-video-close]')
  const videoModal = document.querySelector('[data-video-modal]')
  if (!videoButton || !videoClose || !videoModal) {
    return
  }
  let iframe = null

  const close = () => {
    if (!iframe) {
      return
    }
    enableBodyScroll(videoModal)
    iframe.remove()
    iframe = null
  }
  videoButton.addEventListener('click', e => {
    e.preventDefault()
    iframe = document.createElement('iframe')
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute(
      'allow',
      'accelerometer autoplay encrypted-media gyroscope picture-in-picture'
    )
    iframe.setAttribute('allowfullscreen', 'true')
    iframe.setAttribute('src', videoButton.href)
    videoModal.appendChild(iframe)
    videoModal.classList.add(ACTIVE_CLASS)
    disableBodyScroll(videoModal)
  })

  videoClose.addEventListener('click', () => {
    videoModal.classList.remove(ACTIVE_CLASS)
    setTimeout(close, 300)
  })

  onEscape(() => {
    videoModal.classList.remove(ACTIVE_CLASS)
    setTimeout(close, 300)
  })
})
