import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import anime from 'animejs'
import createEvent from './helpers/createEvent'
import { preloader, preloaderGold, preloaderLogo } from './_constants'

export default function initPreloader(hasSeenPreloader) {
  if (!hasSeenPreloader) {
    anime
      .timeline({
        easing: 'cubicBezier(.25, .1, .25, 1)',
      })
      .add({
        begin: () => {
          disableBodyScroll(document.body)
        },
        targets: preloaderLogo,
        translateY: ['100%', '0%'],
        opacity: [0, 1],
        duration: 800,
      })
      .add({
        delay: 50,
        targets: preloaderGold,
        translateY: ['100%', '0%'],
        duration: 1000,
        easing: 'linear',
      })
      .add({
        delay: 300,
        targets: preloaderGold,
        translateX: '100%',
        opacity: 0,
        duration: 900,
        easing: 'linear',
      })
      .add({
        targets: preloaderLogo,
        translateY: '100%',
        opacity: 0,
        duration: 800,
        complete: () => {
          enableBodyScroll(document.body)
          window.dispatchEvent(createEvent('preloaderEnd'))
        },
      })
      .add({
        targets: preloader,
        opacity: 0,
        duration: 200,
      })
  } else {
    anime({
      targets: preloader,
      opacity: 0,
      duration: 800,
    })
  }
}
