import debounce from './helpers/debounce'

function guestPlaholder(el, name) {
  const guestPlaceholder = el.querySelector(`[data-search-guests="${name}"]`)
  const guestInput = el.querySelector(`#${name}`)

  guestInput.onchange = debounce(e => {
    guestPlaceholder.innerHTML = e.target.value
  }, 100)
}

function datePlaceholder(el) {
  const inputs = el.querySelectorAll(`[data-date-input]`)
  let today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }

  today = `${yyyy}-${mm}-${dd}`

  inputs.forEach(item => {
    item.setAttribute('min', today)
    item.onchange = e => {
      item.classList.remove(e.target.value ? 'empty' : 'selected')
      item.classList.add(e.target.value ? 'selected' : 'empty')
    }
  })
}

export default function(el) {
  guestPlaholder(el, 'adults')
  guestPlaholder(el, 'children')
  datePlaceholder(el)
}
