import Dropkick from 'dropkickjs'
import createEvent from './helpers/createEvent'

export default function(input) {
  new Dropkick(input, {
    mobile: false,
    change: () => {
      input.dispatchEvent(createEvent('input'))
    },
  })
}
