import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import isMobile from 'ismobilejs'
import escapeKey from './helpers/escape-key'
import {
  header,
  menuEl,
  container,
  HEADER_MENU_CONTAINER_CLASS,
  back,
  toggleEl,
  HEADER_TOGGLE_CLASS,
  HEADER_OPEN_CLASS,
  HEADER_MENU_CLASS,
} from './_constants'

let opened = false

export function setScrollHeader() {
  if (!header.classList.contains('header-scroll')) {
    header.classList.add('header-scroll')
  }
}

export function removeScrollHeader() {
  header.classList.remove('header-scroll')
}

function toggleMenu(newOpened) {
  if (newOpened === opened) {
    return
  }
  opened = newOpened
  const animationItems = menuEl.querySelectorAll('[data-animation]')
  if (opened) {
    disableBodyScroll(container)
    setTimeout(() => {
      container.classList.add(HEADER_MENU_CONTAINER_CLASS)
      animationItems.forEach(el => el.classList.add('is-loaded'))
    }, 300)
    back.addEventListener('click', () => toggleMenu(false))
  } else {
    enableBodyScroll(container)
    menuEl.scrollTop = 0
    setTimeout(
      () => container.classList.remove(HEADER_MENU_CONTAINER_CLASS),
      300
    )
    setTimeout(() => {
      animationItems.forEach(el => el.classList.remove('is-loaded'))
      back.removeEventListener('click', () => toggleMenu(false))
    }, 500)
  }
  if (toggleEl) {
    toggleEl.classList.toggle(HEADER_TOGGLE_CLASS)
  }
  if (menuEl) {
    menuEl.classList.toggle(HEADER_MENU_CLASS)
  }
  if (header) {
    header.classList.toggle(HEADER_OPEN_CLASS)
  }
}

if (toggleEl) {
  toggleEl.addEventListener('click', () => {
    toggleMenu(!opened, toggleEl)
  })
}

if (isMobile().any) {
  container.classList.add('header-popup-container_mobile')
}

escapeKey(() => {
  toggleMenu(false)
})

export default toggleMenu
