import clickOutside from 'click-outside'
import { OPENED_CLASS } from './_constants'

export default function(el) {
  const toggle = el.querySelector('[data-dropdown-toggle]')
  toggle.addEventListener('click', () => el.classList.toggle(OPENED_CLASS))

  clickOutside(el, () => {
    if (el.classList.contains(OPENED_CLASS)) {
      el.classList.remove(OPENED_CLASS)
    }
  })
}
