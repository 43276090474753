import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import GoogleMap from './GoogleMap'
import onEscape from './helpers/escape-key'
import { OPEN_CLASS } from './_constants'

export default function initModalEvents(page) {
  const modal = document.querySelector('[data-modal-events]')

  if (!modal) return

  const modalClose = modal.querySelector('[data-modal-events-close]')
  const modalOpen = page.querySelectorAll('[data-modal-events-open]')
  const modalMap = document.querySelector('[data-modal-events-map]')
  const googleMap = modalMap && new GoogleMap(modalMap)
  const close = () => {
    modal.classList.remove(OPEN_CLASS)
    setTimeout(() => enableBodyScroll(modal), 300)
  }
  const open = () => {
    disableBodyScroll(modal)
    modal.classList.add(OPEN_CLASS)
  }

  onEscape(close)
  modalClose.addEventListener('click', close)

  modalOpen.forEach(btn => {
    let markersData = null

    btn.addEventListener('click', e => {
      e.preventDefault()
      open()

      if (!markersData) {
        fetch(btn.dataset.markersUrl)
          .then(res => res.json())
          .then(json => {
            googleMap.loadThenSetMarkers(json)
            googleMap.setDefaultView()
            markersData = json
          })
      } else {
        googleMap.loadThenSetMarkers(markersData)
      }
    })
  })
}
