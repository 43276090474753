/**
 * Entry point for webpack
 * Import styles, fonts, icons, scripts and other things that your project design requires here
 */
import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'
import barba from '@barba/core'
import Splitting from 'splitting'
import isMobile from 'ismobilejs'
import toggleHeaderMenu from './scripts/header'
import initScrollHeader from './scripts/scroll-header'
import initDestinationsFilters from './scripts/destinations-filters'
import swiperObserver from './scripts/init-carousel'
import initScrollTo from './scripts/scroll-to'
import initSelect from './scripts/init-select'
import GoogleMap from './scripts/GoogleMap'
import initDropdown from './scripts/init-dropdown'
import initSearchForm from './scripts/init-search'
import initAnimations from './scripts/css-animations'
import initStickyFooter from './scripts/sticky-animation'
import lazyLoad from './scripts/init-lazy-load'
import initAccordion from './scripts/init-accordion'
import './scripts/init-video-modal'
import InitFilters from './scripts/InitFilters'

import './styles/main.scss'
import './styles/_form.scss'
import './styles/_cta.scss'
import './styles/_blocks.scss'
import './styles/_editable.scss'
import './styles/_extends.scss'
import './styles/_mixins.scss'
import './styles/_atomic.scss'
import './styles/_fonts.scss'
import './styles/_animations.scss'

import form from './form'
import customSelects from './scripts/init-custom-selects'
import initModalEvents from './scripts/init-modal-events'
import initModalGallery from './scripts/init-modal-gallery'
import initParallax from './scripts/init-parallax'
import initModalTeam from './scripts/init-modal-team'
import initPreloader from './scripts/init-preloader'
import sectionCounter from './scripts/init-sections-counter'
import replaceSelector from './scripts/replace-selector'
import {
  barbaContainer,
  barbaWrapper,
  footer,
  header,
} from './scripts/_constants'

const eventListenersRemover = []

// fix HMR https://github.com/webpack-contrib/grunt-webpack/issues/132#issuecomment-292565027
if (module.hot) {
  module.hot.accept()
}
let hasSeenPreloader =
  Boolean(localStorage.getItem('hasSeenPreloader')) || false

// initialize things like header, footer
const oneTimeThings = isToolbarVisible => {
  if (!isToolbarVisible) {
    barbaWrapper.classList.remove('disable-transition')
  }
  // svg sprites support in IE11
  svg4everybody()
  document.documentElement.classList.add('dom-loaded')
  // init scroll
  ;[document.querySelector('[data-header-menu]'), footer].forEach(el =>
    el.querySelectorAll('[data-scroll-to]').forEach(initScrollTo)
  )
  initStickyFooter()
  document.documentElement.classList.add(isMobile().any ? 'mobile' : 'desktop')
  initParallax()
  initPreloader(hasSeenPreloader)

  const initCssAnimations = () => initAnimations(header)

  if (!hasSeenPreloader) {
    window.addEventListener('preloaderEnd', initCssAnimations)
    eventListenersRemover.push({
      el: window,
      event: 'preloaderEnd',
      func: initCssAnimations,
    })
  } else {
    initCssAnimations()
  }

  document
    .querySelector('[data-to-newsletter]')
    .addEventListener('click', e => {
      e.preventDefault()
      toggleHeaderMenu(false)
    })
}

// initialize things inside of data-barba="container"
const onPageLoad = (page, isToolbarVisible) => {
  // object-fit support in IE11
  objectFitImages()
  initScrollHeader()
  initModalEvents(page)
  initModalGallery(page)
  initModalTeam(page)
  sectionCounter(page)

  // init select
  page.querySelectorAll('[data-select]').forEach(initSelect)
  customSelects(page, eventListenersRemover)
  // init carousel
  page.querySelectorAll('[data-swiper]').forEach(swiper => {
    swiperObserver.observe(swiper)
  })
  // init accordion
  page.querySelectorAll('[data-accordion]').forEach(initAccordion)
  // init map
  page.querySelectorAll('[data-google-map]').forEach(el => new GoogleMap(el))
  // init lazy loading
  page.querySelectorAll('[data-lazy-image]').forEach(lazyLoad.observe)
  // init dropdown
  page.querySelectorAll('[data-dropdown]').forEach(initDropdown)
  // init search form
  page.querySelectorAll('[data-search-form]').forEach(initSearchForm)
  // init filter
  page
    .querySelectorAll('[data-destinations-filters]')
    .forEach(initDestinationsFilters)
  page
    .querySelectorAll('[data-filter-container]')
    .forEach(container => new InitFilters(container))
  form(page)

  if (!isToolbarVisible) {
    Splitting()
  }

  page.querySelectorAll('[data-scroll-to]').forEach(initScrollTo)

  // init stagger elements
  document.querySelectorAll('[data-animation="stagger-list"]').forEach(list => {
    const delay = getComputedStyle(list).getPropertyValue('--delay') || 0
    list.querySelectorAll('[data-animation^="stagger-"]').forEach((item, i) => {
      item.style.setProperty('--delay', `${parseFloat(delay) + i / 7}s`)
    })
  })

  page.querySelectorAll('[data-split-text]').forEach(target => {
    const results = Splitting({
      target,
      by: 'words',
    })

    results[0].words.forEach((word, i) =>
      word.style.setProperty('--delay', `${i / 10}s`)
    )
  })

  const initCssAnimations = () => initAnimations(page)

  if (!hasSeenPreloader) {
    window.addEventListener('preloaderEnd', initCssAnimations)
    localStorage.setItem('hasSeenPreloader', 'true')
    hasSeenPreloader = true
    eventListenersRemover.push({
      el: window,
      event: 'preloaderEnd',
      func: initCssAnimations,
    })
  } else {
    initCssAnimations()
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const isToolbarVisible = !!document.querySelector('#ccm-toolbar')

  oneTimeThings(isToolbarVisible)
  onPageLoad(barbaContainer, isToolbarVisible)

  if (!isToolbarVisible) {
    barba.init({
      // If server response is longer then 5s, page will reload completely.
      // You can set bigger values, but in case of long server response user should see something happens.
      timeout: 5000,
      transitions: [
        {
          beforeLeave() {
            setTimeout(() => toggleHeaderMenu(false), 300)

            if (eventListenersRemover.length) {
              eventListenersRemover.forEach(listener => {
                const { el, event, func } = listener

                el.removeEventListener(event, func)
              })
            }
          },
          enter({ next }) {
            replaceSelector(['.lang'], next.html)
            window.scrollTo(0, 0)
            onPageLoad(next.container)
          },
        },
      ],
    })
  }
})
