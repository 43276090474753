const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!entry.target.closest('.cover')) {
          entry.target.classList.add('is-loaded')
        } else {
          setTimeout(() => entry.target.classList.add('is-loaded'), 500)
        }
        observer.unobserve(entry.target)
      }
    })
  },
  { threshold: 0, rootMargin: '0px 0px -80px' }
)

export default function(container) {
  const elements = container.querySelectorAll('[data-animation]')

  elements.forEach(el => {
    observer.observe(el)
  })
}
