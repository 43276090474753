import { LOADED_CLASS, STARTED_CLASS } from './_constants'

const lazyLoad = {}

const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      // if you reload page or go to previous page in history
      // load images above scrolling position too
      if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
        lazyLoad.load(entry.target)
        observer.unobserve(entry.target)
      }
    })
  },
  { rootMargin: '150px 0px' }
)

/**
 * @param img [Element] - image to be loaded immediately
 */
lazyLoad.load = img => {
  if (img.classList.contains(STARTED_CLASS)) return
  ;['srcset', 'src', 'poster'].forEach(attr => {
    if (attr in img.dataset) {
      img.setAttribute(attr, img.dataset[attr])
    }
  })

  img.classList.add('started')

  img.onload = () => {
    img.classList.add(LOADED_CLASS)
  }
}

/**
 * @param img [Element] - image to be loaded when document will be scrolled to it
 */
lazyLoad.observe = img => {
  observer.observe(img)
}

export default lazyLoad
