import scrollPos from './helpers/scroll-position'
import { setScrollHeader, removeScrollHeader } from './header'

export default function() {
  window.addEventListener('scroll', () => {
    if (scrollPos() > 100) {
      setScrollHeader()
    } else {
      removeScrollHeader()
    }
  })
}
