import isMobile from 'ismobilejs'
import { barbaWrapper, footer } from './_constants'
import throttle from './helpers/throttle'

export default function() {
  if (isMobile().any) {
    footer.classList.add('no-sticky')

    return
  }

  const makeSpaceForFooter = throttle(() => {
    barbaWrapper.style.marginBottom = `${footer.clientHeight}px`
  }, 100)

  if (footer && barbaWrapper) {
    makeSpaceForFooter()
    window.addEventListener('resize', makeSpaceForFooter)
  }
}
