// eslint-disable-next-line max-classes-per-file
import { Loader } from 'google-maps'
import anime from 'animejs'
import style from './google-map/style.json'
import debounce from './helpers/debounce'

let google = null
let googleLoadPromise
const callbacks = []

const withGoogle = callback => {
  if (google) {
    callback()
  } else {
    callbacks.push(callback)
    if (!googleLoadPromise) {
      googleLoadPromise = new Loader(window.googleApisKey)
        .load()
        .then(googleObj => {
          google = googleObj
          callbacks.forEach(cb => cb())
        })
    }
  }
}

const toggle = (targets, show = false, onComplete) => {
  anime({
    targets,
    duration: 0,
    opacity: show ? 100 : 0,
    complete: () => {
      targets.style.pointerEvents = show ? 'auto' : 'none'
      onComplete()
    },
  })
}

export default class GoogleMap {
  constructor(element) {
    this.element = element
    this.img = document.querySelector('[data-replace-img]')
    this.title = document.querySelector('[data-replace-title]')
    this.subTitle = document.querySelector('[data-replace-sub-title]')
    this.text = document.querySelector('[data-replace-text]')
    this.link = document.querySelector('[data-replace-link]')
    this.curtains = document.querySelectorAll('[data-replace-curtain]')
    this.video = document.querySelector('[data-video-button]')
    this.container = document.querySelectorAll('[data-replace-container]')
    withGoogle(() => {
      this.init()
    })
    this.loadThenSetMarkers = args => withGoogle(() => this.setMarkers(args))
  }

  setNewContent(content) {
    // uncomment if need to open link on mobile
    // if (isMobile(window.navigator).any) {
    //   window.location.assign(content.link)
    // }

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 800,
    })

    tl.add({
      begin: () => {
        this.curtains.forEach(el => el.classList.add('opened'))
      },
    })
      .add({
        targets: '[data-replace-curtain]',
        begin: () => {
          this.title.innerHTML = content.name
          this.text.innerHTML = content.description
          this.subTitle.innerHTML = content.location
          this.img.src = content.img
          this.img.srcset = content.img2x

          toggle(
            this.video,
            !!content.video,
            () => (this.video.href = content.video || '')
          )
          toggle(
            this.link,
            !!content.link,
            () => (this.link.href = content.link || '')
          )
        },
      })
      .add({
        targets: this.img,
        scale: 1.1,
      })
      .add({
        targets: this.img,
        scale: 1,
        begin: () => {
          this.curtains.forEach(el => el.classList.remove('opened'))
        },
      })
  }

  // eslint-disable-next-line class-methods-use-this
  infoWindowContent(p) {
    return `
      <div class="google-maps-popup pt-18 pb-18 medium-pt-0 medium-pb-0 pl-24 pr-24 medium-pl-0 medium-pr-0">
        <div class="google-maps-popup__location mb-8">
          <p>${p.location}</p>
        </div>
          <div class="google-maps-popup__title mb-32 medium-mb-16">
            <h1>${p.title}</h1>
          </div>
          <div class="google-maps-popup__links">
            <a href="${p['request-route']}" class="google-maps-popup__link mr-24 medium-mr-16">
              <span>Request</span>
            </a>
            <a class="link-with-arrow position-relative zi-2 ml-40 medium-ml-0" href="${p['discover-route']}">
              <span class="link-with-arrow__text mr-16 bolder">Discover the hotel</span>
              <span class="link-with-arrow__icon mr-16">
                <svg viewBox="0 0 16 6" width="16" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 3h14M13 5l2-2-2-2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </span>
            </a>
          </div>
      </div>
    `
  }

  addMarker(coords, url, item, CustomMarker, delay) {
    const marker = new google.maps.Marker({
      position: coords,
      category: item.category,
      info: item.info,
      icon: {
        url,
        anchor: new google.maps.Point(16, 16),
      },
      optimized: false,
      opacity: 0,
      map: this.map,
    })

    const overlay = new CustomMarker(
      {
        position: new google.maps.LatLng(coords),
        map: this.map,
      },
      delay
    )

    this.markers.push({ marker, overlay })

    return marker
  }

  // eslint-disable-next-line class-methods-use-this
  getPoint(coords) {
    try {
      const { lat, lng } = JSON.parse(coords)
      return new google.maps.LatLng(lat, lng)
    } catch (e) {
      return null
    }
  }

  filterMarkers(category) {
    this.markers.forEach(m => {
      const { marker, overlay } = m

      if (marker.category === category || category.length === 0) {
        marker.setVisible(true)
        overlay.show()
        this.bounds.extend(marker.getPosition())
      } else {
        marker.setVisible(false)
        overlay.hide()
      }
      this.map.fitBounds(this.bounds)
    })
  }

  clearMarkers() {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers = []
  }

  setDefaultView() {
    this.map.fitBounds(this.bounds)
    this.map.setZoom(3)
  }

  setMarkers(markersCoords) {
    const { dataset } = this.element
    this.clearMarkers()
    this.markers = markersCoords.map(({ lat, lng, ...card }) => {
      const marker = new google.maps.Marker({
        position: { lat, lng },
        icon: dataset.markerImage,
        map: this.map,
      })
      if ('infoWindow' in dataset) {
        google.maps.event.addListener(marker, 'click', () => {
          this.infoWindow.setContent(this.infoWindowContent(card))
          this.infoWindow.open(this.map, marker)
        })
      }

      this.bounds.extend(marker.position)
      return marker
    })
  }

  init() {
    const { dataset } = this.element
    this.filters = document.querySelectorAll('[data-map-filter]')
    this.centerPosition = this.getPoint(dataset.center)
    this.markers = []
    this.infoWindow = new google.maps.InfoWindow()
    this.bounds = new google.maps.LatLngBounds()
    this.options = dataset.options ? JSON.parse(dataset.options) : []
    this.map = new google.maps.Map(this.element, {
      center: this.centerPosition,
      styles: style,
      disableDefaultUI: 'disableDefaultUi' in dataset,
      ...this.options,
    })

    class CustomMarker extends google.maps.OverlayView {
      constructor(opts, delay) {
        super()
        this.setValues(opts)
        this.marker = document.createElement('div')
        this.delay = delay
        this.observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              anime({
                targets: this.marker,
                begin: () => {
                  setTimeout(() => {
                    this.marker.classList.add('show')
                  }, this.delay)
                },
                complete: () => {
                  this.marker.classList.add('pulse')
                },
              })
            }
          })
        })
      }

      onAdd() {
        const panes = this.getPanes()

        this.marker.classList.add('custom-marker')
        this.marker.innerHTML =
          '<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '<path d="M3 6c.339-1.525 1.5-2.705 3-3a3.802 3.802 0 01-3-3c-.339 1.525-1.5 2.705-3 3a3.802 3.802 0 013 3z" fill="currentColor"/>' +
          '</svg>' +
          '<div class="custom-marker__pulse"></div>'
        panes.overlayLayer.appendChild(this.marker)

        this.observer.observe(this.marker)
      }

      draw() {
        const point = this.getProjection().fromLatLngToDivPixel(this.position)
        if (point) {
          this.marker.style.left = `${point.x - 16}px`
          this.marker.style.top = `${point.y - 16}px`
        }
      }

      removeActive() {
        this.marker.classList.remove('active')
      }

      addActive() {
        this.marker.classList.add('active')
      }

      hide() {
        if (this.marker) {
          anime({
            targets: this.marker,
            opacity: 0,
            duration: 500,
            easing: 'cubicBezier(.3, .1, .3, .1)',
          })
        }
      }

      show() {
        if (this.marker) {
          anime({
            targets: this.marker,
            opacity: 1,
            duration: 750,
            easing: 'cubicBezier(.3, .1, .3, .1)',
          })
        }
      }
    }

    if (dataset.markersUrl) {
      fetch(dataset.markersUrl)
        .then(response => response.json())
        .then(markersData => {
          if ('markerOverlay' in dataset) {
            markersData.forEach(item => {
              this.addMarker(
                item.coordinates,
                dataset.markerImage,
                item,
                CustomMarker,
                1000 + Math.random() * 400
              )
            })

            markersData.forEach(item => {
              this.bounds.extend(item.coordinates)
            })

            this.markers.forEach(m => {
              const { marker, overlay } = m
              marker.addListener(
                'click',
                debounce(() => {
                  this.markers.forEach(mrk => mrk.overlay.removeActive())
                  overlay.addActive()
                  this.setNewContent(marker.info)
                }, 100)
              )
            })
          } else {
            this.setMarkers(markersData)
          }
        })
    } else if (dataset.coordinates) {
      const coordinates = JSON.parse(dataset.coordinates)

      this.setMarkers(Array.isArray(coordinates) ? coordinates : [coordinates])
    } else {
      return
    }

    this.filters.forEach(item => {
      item.addEventListener('click', () => {
        const { mapFilter } = item.dataset

        if (item.classList.contains('is-active')) {
          item.classList.remove('is-active')
          this.markers.forEach(m => {
            const { marker, overlay } = m
            marker.setVisible(true)
            overlay.show()
          })
          this.map.fitBounds(this.bounds)
        } else {
          this.filters.forEach(el => el.classList.remove('is-active'))
          item.classList.add('is-active')
          this.filterMarkers(mapFilter)
        }
      })
    })
  }
}
