import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import onEscape from './helpers/escape-key'
import { OPEN_CLASS } from './_constants'

export default function initModalTeam(page) {
  const modal = document.querySelector('[data-modal-team]')

  if (!modal) return

  const modalClose = modal.querySelector('[data-modal-team-close]')
  const modalOpen = page.querySelectorAll('[data-modal-team-open]')
  const memberImage = document.querySelector('[data-modal-team-image]')
  const memberName = document.querySelector('[data-modal-team-name]')
  const memberPosition = document.querySelector('[data-modal-team-position]')
  const memberText = document.querySelector('[data-modal-team-text]')
  const close = () => {
    modal.classList.remove(OPEN_CLASS)
    setTimeout(() => enableBodyScroll(modal), 300)
  }
  const open = () => {
    disableBodyScroll(modal)
    modal.classList.add(OPEN_CLASS)
  }
  const setContent = ([{ name, position, image, image2x, textHtml }]) => {
    memberImage.setAttribute('src', image)
    if (image2x) memberImage.setAttribute('srcset', `${image2x} 2x`)
    memberName.innerHTML = name
    memberPosition.innerHTML = position
    memberText.innerHTML = textHtml
  }

  onEscape(close)
  modalClose.addEventListener('click', close)

  modalOpen.forEach(btn => {
    let memberInfo = null

    btn.addEventListener('click', e => {
      e.preventDefault()
      open()

      if (!memberInfo) {
        fetch(btn.dataset.memberUrl)
          .then(res => res.json())
          .then(json => {
            setContent(json)
            memberInfo = json
          })
      } else {
        setContent(memberInfo)
      }
    })
  })
}
