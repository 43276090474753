import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Swiper from 'swiper'
import onEscape from './helpers/escape-key'
import { OPEN_CLASS } from './_constants'

const defaultOptions = {
  keyboard: true,
  navigation: true,
  preloadImages: false,
  watchSlidesVisibility: true,
  speed: 750,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
    loadOnTransitionStart: true,
  },
}

const thumbsOptions = {
  ...defaultOptions,
  spaceBetween: 8,
  slidesPerView: 2,
  breakpoints: {
    260: {
      slidesPerView: 3,
    },
    460: {
      slidesPerView: 4,
    },
    640: {
      slidesPerView: 6,
    },
    880: {
      slidesPerView: 8,
    },
    1024: {
      slidesPerView: 8,
    },
    1200: {
      slidesPerView: 12,
    },
  },
}

export default function initModalGallery(page) {
  const modal = document.querySelector('[data-modal-gallery]')

  if (!modal) return

  const swiperContainer = modal.querySelector('.swiper-container')
  const thumbsSwiperContainer = modal.querySelector('.swiper-container-thumbs')
  const swiper = new Swiper(swiperContainer, {
    ...defaultOptions,
    slidesPerView: 1,
    spaceBetween: 0,
    thumbs: {
      swiper: new Swiper(thumbsSwiperContainer, thumbsOptions),
    },
    navigation: {
      nextEl: modal.querySelector('[data-swiper-next]'),
      prevEl: modal.querySelector('[data-swiper-prev]'),
    },
  })

  const modalClose = modal.querySelector('[data-modal-gallery-close]')
  const modalOpen = page.querySelectorAll('[data-modal-gallery-open]')
  const close = () => {
    modal.classList.remove(OPEN_CLASS)
    swiper.thumbs.swiper.removeAllSlides()
    swiper.removeAllSlides()
    setTimeout(() => enableBodyScroll(modal), 300)
  }
  const open = () => {
    disableBodyScroll(modal)
    modal.classList.add(OPEN_CLASS)
  }
  const updateSwiper = (s, slides) => {
    s.appendSlide(slides)
    s.lazy.load()
    s.update()
  }
  const getSlideMarkup = slide => `
    <div class="swiper-slide modal-gallery__slide">
      <img data-src="${slide.image}" data-srcset="${slide.image2x}" class="swiper-lazy mb-16" alt />
      <p class="modal-gallery__label mb-8">${slide.description}</p>
      <a class="modal-gallery__link" href="${slide.link}">See Destination</a>
    </div>  
  `
  const getThumbnailMarkup = slide => `
    <div class="swiper-slide">
        <img data-src="${slide.thumbnail}" class="swiper-lazy" alt>
    </div>
  `

  onEscape(close)
  modalClose.addEventListener('click', close)

  swiper.on('lazyImageReady', s => s.update())
  swiper.thumbs.swiper.on('lazyImageReady', s => s.update())

  modalOpen.forEach(btn => {
    let slides = null
    let thumbs = null

    btn.addEventListener('click', e => {
      e.preventDefault()
      open()

      if (!slides) {
        fetch(btn.dataset.slidesUrl)
          .then(res => res.json())
          .then(json => {
            slides = json.map(slide => getSlideMarkup(slide))
            thumbs = json.map(slide => getThumbnailMarkup(slide))

            updateSwiper(swiper, slides)
            updateSwiper(swiper.thumbs.swiper, thumbs)
          })
      } else {
        updateSwiper(swiper, slides)
        updateSwiper(swiper.thumbs.swiper, thumbs)
      }
    })
  })
}
