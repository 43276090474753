/**
 * Send form asynchronously via fetch
 * Handle animations by toggling success and error classes
 *
 * Usage:
 *
 * import sendFormAsync from './send-form-async';
 *
 * const responseIsCorrect = response => response.ok
 *
 * form.addEventListener('submit', (e) => {
 *   e.preventDefault();
 *   sendFormAsync(form).then(({ error, response, success }) => {
 *     if (responseIsCorrect(response)) {
 *       success()
 *     } else {
 *       error()
 *     }
 *   });
 * })
 */

const defaults = {
  animationDuration: 2000,
  animationClass: 'form-animation--progress',
  errorClass: 'form-animation--error',
  networkErrorClass: 'form-animation--error-network',
  successClass: 'form-animation--success',
}

/**
 * @return Promise of POST request of form
 */
const postForm = form => {
  const url = form.getAttribute('action')
  const formData = new FormData()
  form.querySelectorAll('[name]').forEach(input => {
    const value = input.type === 'file' ? input.files[0] : input.value
    formData.append(input.name, value)
  })
  return fetch(url, {
    method: 'POST',
    body: formData,
  })
}

export default (form, params = {}) => {
  const options = { ...defaults, ...params }

  const animationPromise = new Promise(resolve =>
    setTimeout(resolve, options.animationDuration)
  )

  const submit = form.querySelector('[type="submit"]')
  submit.disabled = true
  form.classList.add(options.animationClass)
  form.classList.remove(options.errorClass)
  form.classList.remove(options.successClass)

  const finish = () => {
    submit.disabled = false
    form.classList.remove(options.animationClass)
  }

  const success = () => {
    form.classList.add(options.successClass)
    form.reset()

    // reset doesn't work for hidden inputs but flatpickr uses hidden input
    form
      .querySelectorAll('.flatpickr-input[type="hidden"]')
      .forEach(i => (i.value = ''))
  }

  const error = () => {
    form.classList.add(options.errorClass)
  }

  return new Promise((resolve, reject) =>
    Promise.all([postForm(form), animationPromise]).then(
      ([response]) => {
        finish()
        resolve({ error, response, success })
      },
      args => {
        finish()
        form.classList.add(options.networkErrorClass)
        reject(args)
      }
    )
  )
}
