import Muuri from 'muuri'

const filtersLength = 5

export default function(container) {
  const grid = new Muuri(container, {
    items: '[data-filter-item]',
  })
  const allItems = grid.getItems()
  const filters = container.querySelectorAll('[data-filter-item]')
  const filtersToggle = container.querySelector('[data-destinations-toggle]')
  const hideItems = () => {
    grid.filter(item => {
      const el = item.getElement()
      return (
        el.dataset.destinationsToggle !== undefined ||
        !el.classList.contains('is-hidden')
      )
    })
  }

  if (filters.length <= filtersLength) {
    filtersToggle.classList.add('is-hidden')
    return
  }

  filters.forEach((item, index) => {
    if (index + 1 > filtersLength) {
      item.classList.add('is-hidden')
    }
  })

  hideItems()
  filtersToggle.addEventListener('click', () => {
    if (filtersToggle.classList.contains('is-active')) {
      filtersToggle.classList.remove('is-active')
      hideItems()
    } else {
      filtersToggle.classList.add('is-active')
      grid.show([...allItems])
    }
  })
}
