const replaceSelectors = (selectors = [], nextHtml = '') => {
  const parsedNextHtml = new DOMParser().parseFromString(nextHtml, 'text/html')

  selectors.forEach(selector => {
    const currentElement = document.querySelector(selector)
    const nextElement = parsedNextHtml.querySelector(selector)

    currentElement.parentNode.replaceChild(nextElement, currentElement)
  })
}

export default replaceSelectors
