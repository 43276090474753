import PhotoSwipe from 'photoswipe'
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/src/css/default-skin/default-skin.scss'
import { pswp } from './_constants'

export default function(swiper) {
  const page = swiper.el
  const galleryTriggers = page.querySelectorAll('[data-gallery-trigger]')

  if (!galleryTriggers.length) {
    return
  }

  const items = []
  const getThumbBoundsFn = index => {
    const el = galleryTriggers[index]
    const img = el.querySelector('img')

    if (img) {
      const rect = img.getBoundingClientRect()
      const pageYScroll =
        window.pageYOffset || document.documentElement.scrollTop
      return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width,
      }
    }

    return {
      x: window.innerWidth / 2,
      y:
        (window.scrollY || document.documentElement.scrollTop) +
        window.innerHeight / 2,
      w: 0,
    }
  }
  let gallery
  let opened = false
  const loadFullImage = slide =>
    new Promise(resolve => {
      const fullImg = new Image()
      fullImg.addEventListener('load', () => {
        slide.fullSizes = {}
        slide.fullSizes.w = fullImg.naturalWidth
        slide.fullSizes.h = fullImg.naturalHeight
        Object.assign(slide, slide.fullSizes)
        if (opened) {
          gallery.updateSize(true)
        }
        resolve()
      })
      fullImg.src = slide.src
    })
  const openGallery = index => {
    gallery = new PhotoSwipe(pswp, PhotoSwipeUiDefault, items, {
      index,
      getThumbBoundsFn,
    })

    gallery.init()
    document.documentElement.classList.add('photoswipe-opened')

    gallery.listen('close', () => {
      setTimeout(() => {
        document.documentElement.classList.remove('photoswipe-opened')
        opened = false
      }, 100)
    })

    gallery.listen('beforeChange', () => {
      loadFullImage(gallery.currItem)
    })

    gallery.listen('afterChange', () => {
      swiper.slideTo(gallery.getCurrentIndex(), 0)
    })

    opened = true
  }

  galleryTriggers.forEach((el, index) => {
    const img = el.querySelector('img')
    const title = el.dataset.galleryTitle
    const config = {
      src: el.dataset.fullscreenImage || img.getAttribute('src'),
      msrc: img ? img.getAttribute('src') : null,
      title: title || null,
      w: el.clientWidth,
      h: el.clientHeight,
    }

    items.push(config)

    if (img) {
      img.addEventListener('load', () => {
        config.thumbSizes = {}
        config.thumbSizes.w = img.clientWidth
        config.thumbSizes.h = img.clientHeight
        if (!('fullSizes' in config)) {
          Object.assign(config, config.thumbSizes)
        }
      })

      el.addEventListener('click', () => {
        loadFullImage(config).then(() => {
          openGallery(index)
        })
      })
    }
  })

  const args = window.location.hash.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})

  if ('pid' in args) {
    loadFullImage(items[args.pid - 1]).then(() => {
      openGallery(Number(args.pid) - 1)
    })
  }
}
