import clickOutside from 'click-outside'
import createEvent from './helpers/createEvent'
import {
  SELECT_ADDITIONAL_INFO_CLASS,
  SELECT_COLLAPSED_CLASS,
  SELECT_ERROR_CLASS,
  SELECT_ERROR_MESSAGE_EMPTY,
  SELECT_OPEN_CLASS,
  SELECT_OPTION_SELECTED_CLASS,
} from './_constants'

window.ResizeObserver = ResizeObserver

const toggleClass = (select, className) => {
  select.classList.toggle(className)
}

const checkCollapsed = (select, selection) =>
  select.classList.toggle(
    SELECT_COLLAPSED_CLASS,
    selection.innerText.trim() !== ''
  )

const toggleOpen = (select, selection) => {
  if (select.classList.contains(SELECT_OPEN_CLASS)) {
    toggleClass(select, SELECT_OPEN_CLASS)
    checkCollapsed(select, selection)
    select.dispatchEvent(createEvent('closeSelect'))
  }
}

const removeSelectClass = el =>
  el.classList.remove(SELECT_OPTION_SELECTED_CLASS)

const changeSelected = (el, select) => {
  const nativeSelects = select.querySelectorAll('select')
  el.innerHTML = ''

  nativeSelects.forEach((nativeSelect, i) => {
    const selectedOptions =
      Array.from(nativeSelect.options).filter(opt => opt.selected) || 0
    let label = ''

    if (selectedOptions.length > 1) {
      label += `${selectedOptions.length}`
    } else if (selectedOptions.length === 1) {
      label += selectedOptions[0].dataset.altValue
        ? selectedOptions[0].dataset.altValue
        : selectedOptions[0].value
    }

    if (label === '') {
      label = nativeSelect.dataset.defaultValue || ''
    }

    const additionalInfo = document.createElement('span')

    additionalInfo.classList.add(
      SELECT_ADDITIONAL_INFO_CLASS,
      `${SELECT_ADDITIONAL_INFO_CLASS}-${i}`
    )
    additionalInfo.innerText = label

    el.append(additionalInfo)
  })
}

const removeSelections = (
  nativeSelect,
  options,
  selection,
  select,
  clearLabel = false
) => {
  nativeSelect.selectedIndex = '-1'
  options.forEach(opt => removeSelectClass(opt))

  if (clearLabel) {
    changeSelected(selection, select)
    checkCollapsed(select, selection)
  }
}

const validateSelect = (nativeSelect, select, errorLabel, errorMessage) => {
  if (nativeSelect.validity.valid) {
    select.classList.remove(SELECT_ERROR_CLASS)
    errorLabel.innerText = ''
  } else {
    select.classList.add(SELECT_ERROR_CLASS)
    errorLabel.innerText = errorMessage
  }
}

const optionsInit = (
  options,
  nativeSelect,
  select,
  selection,
  errorLabel,
  page
) => {
  const isMultiple = !nativeSelect.hasAttribute('data-not-multiple')
  const dependentInputs = page.querySelectorAll(
    `[data-depends-on="${nativeSelect.getAttribute('id')}"]`
  )

  options.forEach((option, i) => {
    let oldSelected = null

    option.addEventListener('click', () => {
      if (isMultiple) {
        nativeSelect.options[i].selected = !nativeSelect.options[i].selected
        toggleClass(option, SELECT_OPTION_SELECTED_CLASS)
      } else {
        if (oldSelected === nativeSelect.options[i].selected) {
          removeSelections(nativeSelect, options, selection, select)
          changeSelected(selection, select)
          return
        }

        removeSelections(nativeSelect, options, selection, select)
        nativeSelect.options[i].selected = !nativeSelect.options[i].selected
        option.classList.toggle(
          SELECT_OPTION_SELECTED_CLASS,
          nativeSelect.options[i].selected
        )
        oldSelected = nativeSelect.options[i].selected
      }

      if (dependentInputs) {
        dependentInputs.forEach(input => {
          if (input.hasAttribute('data-custom-select')) {
            input.dispatchEvent(createEvent('clearSelectOptions'))

            return
          }

          const inputEl = input.querySelector('input') || input

          input.value = ''
          inputEl.dispatchEvent(createEvent('input'))
        })
      }
      changeSelected(selection, select)

      if (!isMultiple) {
        toggleOpen(select, selection)
      }

      if (nativeSelect.hasAttribute('required')) {
        validateSelect(
          nativeSelect,
          select,
          errorLabel,
          SELECT_ERROR_MESSAGE_EMPTY
        )
      }

      select.dispatchEvent(createEvent('changeSelectOption'))
      nativeSelect.options[i].dispatchEvent(createEvent('click'))
    })

    if ('selected' in nativeSelect.options[i].dataset) {
      option.dispatchEvent(createEvent('click'))
    }
  })
}
export default function customSelects(page, eventListenersRemover) {
  const selects = page.querySelectorAll('[data-custom-select]')
  const urlParams = new URLSearchParams(window.location.search)

  selects.forEach(select => {
    const selectWrapper = select.querySelector('[data-wrapper]')
    const nativeSelects = select.querySelectorAll('select')
    const lists = select.querySelectorAll('[data-select-list]')
    const selection = select.querySelector('[data-selection]')
    const errorLabel = select.querySelector('[data-error]')

    if (lists.length > 1) {
      lists.forEach(list => {
        const optionsNativeSelect = select.querySelector(
          `#${list.dataset.selectList}`
        )
        const options = list.querySelectorAll('[data-option]')

        optionsNativeSelect.selectedIndex = '-1'
        optionsInit(
          options,
          optionsNativeSelect,
          select,
          selection,
          errorLabel,
          page
        )
      })
    } else {
      const options = lists[0].querySelectorAll('[data-option]')

      optionsInit(
        options,
        nativeSelects[0],
        select,
        selection,
        errorLabel,
        page
      )
    }

    clickOutside(select, () => {
      toggleOpen(select, selection)
    })

    nativeSelects.forEach(nativeSelect => {
      const name = nativeSelect.getAttribute('name')

      nativeSelect.addEventListener('invalid', e => {
        e.preventDefault()
        validateSelect(
          nativeSelect,
          select,
          errorLabel,
          SELECT_ERROR_MESSAGE_EMPTY
        )
      })

      if (urlParams.has(name)) {
        const values = urlParams.getAll(name)

        values.forEach(value => {
          const option = select.querySelector(`[data-option="${value}"]`)

          if (option) {
            option.click()
          }
        })
      }

      checkCollapsed(select, selection)
    })

    select.addEventListener('clearSelectOptions', () => {
      nativeSelects.forEach(nativeSelect => {
        const listToClear = select.querySelector(
          `[data-select-list="${nativeSelect.getAttribute('id')}"]`
        )
        if (listToClear) {
          const optionsToClear = listToClear.querySelectorAll('[data-option]')

          removeSelections(
            nativeSelect,
            optionsToClear,
            selection,
            select,
            true
          )
        } else {
          const optionsToClear = lists[0].querySelectorAll('[data-option]')
          removeSelections(
            nativeSelect,
            optionsToClear,
            selection,
            select,
            true
          )
        }
      })
    })

    select.addEventListener('closeSelect', () => {
      nativeSelects.forEach(nativeSelect =>
        nativeSelect.dispatchEvent(createEvent('change'))
      )
    })

    selectWrapper.addEventListener('click', () => {
      if (select.classList.contains(SELECT_OPEN_CLASS)) {
        checkCollapsed(select, selection)
      }

      toggleClass(select, SELECT_OPEN_CLASS)
    })

    const clearSelect = () =>
      select.dispatchEvent(createEvent('clearSelectOptions'))

    window.addEventListener('formReset', clearSelect)

    eventListenersRemover.push({
      el: window,
      event: 'formReset',
      func: clearSelect,
    })
  })
}
