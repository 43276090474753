export const HEADER_TOGGLE_CLASS = 'header__toggle_opened'
export const HEADER_MENU_CLASS = 'header-menu_opened'
export const HEADER_OPEN_CLASS = 'header_opened'
export const HEADER_MENU_CONTAINER_CLASS = 'header-menu__container_opened'

export const OPEN_CLASS = 'open'
export const OPENED_CLASS = 'is-opened'
export const LOADED_CLASS = 'loaded'
export const STARTED_CLASS = 'started'
export const ACTIVE_CLASS = 'is-active'
export const PARALLAX_CLASS = 'rellax'

export const SELECT_OPEN_CLASS = 'custom-select--open'
export const SELECT_COLLAPSED_CLASS = 'custom-select--collapsed'
export const SELECT_ERROR_CLASS = 'custom-select--error'
export const SELECT_OPTION_SELECTED_CLASS = 'custom-select__option--selected'
export const SELECT_ADDITIONAL_INFO_CLASS = 'custom-select__additional'
export const SELECT_ERROR_MESSAGE_EMPTY = 'Please, enter a value'

export const FILTER_GRID_ATTR = 'data-filter-grid'
export const FILTER_ITEM_ATTR = 'data-filter-item'
export const FILTER_BUTTON_ATTR = 'data-filter-button'
export const FILTER_GROUP_MULTIPLE_ATTR = 'data-multiple'
export const FILTER_GROUP_ATTR = 'data-filter-group'

export const header = document.querySelector('.header')
export const menuEl = document.querySelector('[data-header-menu]')
export const toggleEl = document.querySelector('[data-header-toggle]')
export const container = document.querySelector('.header-menu__container')
export const back = document.querySelector('.header-menu__back')
export const footer = document.querySelector('.footer')
export const barbaWrapper = document.querySelector('[data-barba="wrapper"]')
export const barbaContainer = document.querySelector('[data-barba="container"]')
export const pswp = document.querySelector('.pswp')
export const preloader = document.querySelector('.preloader')
export const preloaderLogo = document.querySelector('.preloader__logo')
export const preloaderGold = document.querySelector('.preloader__gold')
